import styled from "styled-components";

export const Container = styled.div`
margin : 0 auto;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`;

export const HeaderContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
background: #0066a0;
color: #fff;
font-size: 44px;
padding: 50px 20px;

@media (max-width: 1440px) {
    font-size: 34px;
    padding: 30px 20px;
}

@media (max-width: 768px) {
    font-size: 28px;
}
@media (max-width: 425px) {
    font-size: 20px;
}

`;

export const TextContainer = styled.div`
padding: 60px 0px;
font-size: 22px;
max-width: 650px;
text-align: center;
@media (max-width: 1440px) {
    font-size: 20px;
    padding: 25px 0px;
}
@media (max-width: 768px) {
    font-size: 18px;
    padding: 50px;
}
@media (max-width: 425px) {
    font-size: 14px;
    padding: 30px;
}
`;

export const Link= styled.a`
text-decoration: none;
font-size: 22px;
@media (max-width: 1440px) {
    font-size: 20px;
}
@media (max-width: 768px) {
    font-size: 18px;
}
@media (max-width: 425px) {
    font-size: 14px;
}
`;

export const Logo=styled.img`
width:350px;
margin:50px;
@media (max-width: 768px) {
    width:250px;
}
@media (max-width: 425px) {
    width:200px;
}
`;